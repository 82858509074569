const baseUrl = process.env.REACT_APP_BASE_URL
const resourcePath = process.env.REACT_APP_RESOURCE_PATH

export default {
  folders: {
    metadata: '/metadata',
    store: '/links'
  },
  baseUrls: {
    cdn: baseUrl + resourcePath
  }
}
