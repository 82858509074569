import React, {Fragment, PureComponent} from 'react'
import { string, array, bool, object } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import URL from 'url-parse'
import {A, Avatar, Text, Button, Loading, Icon, SpotIllustration} from '@rebrandly/styleguide'

import './LinkGalleryContent.css'

const SOCIALS = [
  { name: 'website', icon: 'ic-favicon' },
  { name: 'email', icon: 'ic-mail', baseUrl: 'https://middleware.rebrandly.com/v1/mail/compose?rb-dashboard-composer=mailto&to=' },
  { name: 'instagram', icon: 'ic-social-instagram-nofill' },
  { name: 'youtube', icon: 'ic-social-youtube-nofill' },
  { name: 'facebook', icon: 'ic-social-facebook-nofill' },
  { name: 'linkedin', icon: 'ic-social-linkedin-nofill' },
  { name: 'twitter', icon: 'ic-social-twitter-nofill' },
  { name: 'whatsapp', icon: 'ic-social-whatsapp-nofill', baseUrl: 'https://wa.me/' }
]

function addProtocolToUrl (shorturl, https) {
  const url = new URL(shorturl, {})
  url.set('protocol', https ? 'https:' : 'http:')
  return url.href
}


class LinkGalleryContent extends PureComponent {
  buildSocialArray = () => SOCIALS.filter(s => this.props.socials[s.name])
      .map(staticInfo => (
        {
          ...staticInfo,
          ...this.props.socials[staticInfo.name]
        }
      ))

  render () {
    const hasUserInfo = this.props.avatar.src && this.props.username.value
    const socialArray = this.buildSocialArray()

    const getInfo = social => {
      switch (social.name) {
        case 'email':
          return {
            ...social,
            href: social.baseUrl + btoa(social.value)
          }

        case 'whatsapp':
          return {
            ...social,
            href: social.baseUrl + social.value.replace('+', '')
          }

        default:
          return {
            ...social,
            href: social.value
          }
      }
    }

    const {shortUrl, https} = this.props.avatar.anchor

    return (
      <div className='App'>
        <div className='App__content Page flex-column'>

          <div className='LinkGallery__content Container--narrow flex-column'>
            {this.props.isLoading && !hasUserInfo
              ? <Loading size='lg' direction='vertical' label='Loading data, please wait...' />
              : <Fragment>
                <div className='flex-column center'>
                  {this.props.avatar.display &&
                    <div className='center-row'>
                      <A href={addProtocolToUrl(shortUrl, https)} disabled={!shortUrl} target='_blank'>
                        <Avatar img={this.props.avatar.src} profile />
                      </A>
                    </div>
                  }
                  {this.props.username.display &&
                    <Text className='Username center-row m-t-16' size='small'>{`@${this.props.username.value}`}</Text>
                  }
                </div>

                {this.props.isLoading
                  ? <div className='m-t-48'>
                    <Loading size='md' label='Loading, please wait...'/>
                  </div>
                  : <Fragment>
                    {!isEmpty(socialArray) &&
                    <div className='LinkGallery__content--social-icons flex center-xs m-t-36'>
                      {socialArray.map(s => {
                        const social = getInfo(s)

                        return (
                          <A key={social.name} href={social.href} target='_blank'>
                            <Icon
                              className={social.name}
                              name={social.icon}
                            />
                          </A>
                        )
                      })}
                    </div>
                    }

                    <div className='center-row m-t-36 flex-column block-xs'>

                      {this.props.links.filter(l => l.active).map(link => {
                        if (link.embedSrc) {
                          return (
                            <div className='VideoWrapper flex-column m-t-8'>
                              <iframe
                                src={link.embedSrc}
                                title={link.title}
                                frameBorder='0'
                                allowFullScreen
                              />
                            </div>
                          )
                        }

                        return (
                          <A className='ellipsis' href={'https://' + link.shortUrl} target='_blank'>
                            <Button
                              className={classNames('m-t-8 ellipsis', {
                                'LinkGallery__content--buttons-squared': !this.props.roundedCorners,
                                'LinkGallery__content--buttons-filled': !this.props.transparentButtons,
                                'LinkGallery__content--buttons-transparent': this.props.transparentButtons
                              })}
                              label={link.title}
                              size='lg'
                              block/>
                          </A>
                        )
                      })}

                    </div>
                  </Fragment>
                }
              </Fragment>
            }

          </div>


          {!this.props.hideWatermark &&
            <div className='center footer flex'>
              <div>
                <SpotIllustration name='ill-spot-linkgallery-by-rb' />
                <A className='footer-linkgallery-a inline' href='https://link.gallery' target='_blank' />
                <A className='footer-rebrandly-a inline' href='https://rebrandly.com' target='_blank' />
              </div>
            </div>
          }

        </div>
      </div>
    )
  }
}

LinkGalleryContent.propTypes = {
  id: string,
  username: object,
  avatar: object,
  links: array,
  socials: object,
  transparentButtons: bool,
  roundedCorners: bool,
  isLoading: bool,
  hideWatermark: bool
}

export default LinkGalleryContent
